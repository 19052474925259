// CustomApplication.js

const { Application,  PlayOverlay, InfoOverlay, ErrorOverlay,PixelStreamingApplicationStyle } = require('@epicgames-ps/lib-pixelstreamingfrontend-ui-ue5.5');
const { Flags} = require('@epicgames-ps/lib-pixelstreamingfrontend-ue5.5');
const {ConnectOverlay} = require('./ConnectOverlay');
const {DisconnectOverlay} = require('./DisconnectOverlay');
const {AFKOverlay} = require('./AFKOverlay');



/*const PixelStreamingApplicationStyles = new StreamingApplicationStyles();
PixelStreamingApplicationStyles.applyStyleSheet();
*/

const PixelStreamingApplicationStyles =new PixelStreamingApplicationStyle();
PixelStreamingApplicationStyles.applyStyleSheet();

let isVolumeMuted = false; // Track volume state

class CustomApplication extends Application {
    
    
    createOverlays() {
        // Customize DisconnectOverlay
        this.disconnectOverlay = new DisconnectOverlay(this.stream.videoElementParent);
        this.disconnectOverlay.onAction(() => this.stream.reconnect());

        // Customize ConnectOverlay
        this.connectOverlay = new ConnectOverlay(this.stream.videoElementParent);

   
  
        this.showLoadingSpinner();
        var wsController = this.stream.signallingProtocol;
        
   
    var isConnected = wsController.isConnected();
   if(isConnected){
    
    wsController.transport.on('message', (msgRaw) => {
        try {
            

            if(msgRaw.message == "You are in Queue"){

                var msgFormate = msgRaw.message +" "+msgRaw.position;

                this.showTextOverlay(msgFormate);
        
        
            }

        } catch (error) {
            console.error("Failed to parse message:", error);
            return;
        }
    })

   }
   
        this.connectOverlay.onAction(() => this.stream.connect());

        // Customize PlayOverlay
        this.playOverlay = new PlayOverlay(this.stream.videoElementParent);

        this.playOverlay.onAction(() => this.stream.videoElementParent);

        // Customize InfoOverlay
        this.infoOverlay = new InfoOverlay(this.stream.videoElementParent);

        // Customize ErrorOverlay
        this.errorOverlay = new ErrorOverlay(this.stream.videoElementParent);

        // Customize AFKOverlay
        this.afkOverlay = new AFKOverlay(this.stream.videoElementParent);
    }

    


    handleStreamerListMessage(messageStreamingList, autoSelectedStreamerId, wantedStreamerId) {
        const waitForStreamer = this.stream.config.isFlagEnabled(Flags.WaitForStreamer);
        const isReconnecting = this.stream.isReconnecting();
        let message = null;
        let allowRestart = true;

        if (!autoSelectedStreamerId) {
            if (waitForStreamer && wantedStreamerId) {
                if (isReconnecting) {
                    message = `Waiting for ${wantedStreamerId} to become available.`;
                    allowRestart = false;
                } else {
                    message = `Gave up waiting for ${wantedStreamerId} to become available. Click to try again`;
                    if (messageStreamingList.ids.length > 0) {
                        message += ` or select a streamer from the settings menu.`;
                    }
                    allowRestart = true;
                }
            } else if (messageStreamingList.ids.length === 0) {
                if (isReconnecting) {
                    message = `Waiting for a streamer to become available.`;
                    
                    allowRestart = false;
                } else {
                    message = `No streamers available. Click to try again.`;
                    allowRestart = true;
                }
            } else {
                message = `Multiple streamers available. Select one from the settings menu.`;
                allowRestart = false;
            }

            if (allowRestart) {
                this.hideLoadingSpinner(); // Hide loading spinner
                this.showDisconnectOverlay(message);
            } else {
                this.showTextOverlay(message);
            }
        }
    }

  onVideoInitialized(){

    this.hideLoadingSpinner();   


    const videoElement = document.getElementById("streamingVideo");

      videoElement.style.zIndex = 100;

    window.parent.postMessage("loadingComplete", "*");

   //this.createNewBtnUi();
    //this.stream.emitConsoleCommand('PixelStreamingEncoderTargetBitrate=50000000');
   this.stream.emitConsoleCommand('t.maxFPS 60');
    

  this.stream.emitConsoleCommand('PixelStreaming.encoder.MinQP 28');
   // this.stream.emitConsoleCommand('PixelStreaming.encoder.MaxQP 32');
 this.stream.emitConsoleCommand('PixelStreaming.WebRTC.MinBitrate 1000');
  //  this.stream.emitConsoleCommand('PixelStreaming.WebRTC.MaxBitrate 100000');

  }

  getStats() {
    this.statsPanel.show();
    var statsElement = this.statsPanel._statsResult;
 
  
    if (!statsElement) {
      return {}; // Return an empty object if no element is found
    }
  
    const statDivs = statsElement.querySelectorAll('div');
  
  
    if (!statDivs.length) {
      return {}; // Return empty object if no divs found
    }
  
    const stats = {};
  
    statDivs.forEach((div, index) => {
      const text = div.textContent.trim(); // Use textContent instead of innerText
  
      // Skip empty divs (check length and trim for any potential white spaces)
      if (text.length === 0) {
        return;
      }
  
  
      const [key, value] = text.split(':').map(item => item.trim());
      console.log(stats[key]);
      if (key && value  && key.toLowerCase() !== 'Players') {

      
        stats[key] = value;
      } else {
      }
    });
  
    return stats;
  }
  
  


onWebRtcAutoConnect() {
    this.showTextOverlay('Auto Connecting Now');
}

onWebRtcConnecting() {
    this.showTextOverlay('Starting connection to Streampixel server, please wait');
}

/*
onDisconnect(event) {
    console.log(event);


*/

/**
 * Handles when Web Rtc has connected
 */
onWebRtcConnected() {
    this.showTextOverlay('Almost there, hold tight- awesomeness loading');
   

    }
        



onWebRtcSdp() {
    this.showTextOverlay('Sharpening pixels and buffing the details...');
}

    // Custom method to show the loading spinner
    showLoadingSpinner() {
        const spinner = document.createElement('div');
        spinner.classList.add('loading-spinner');
        spinner.style.position = 'absolute';
        spinner.style.top = '40%';
        spinner.style.left = '50%';
        spinner.style.transform = 'translate(-50%, -50%)';
        spinner.style.width = '60px';
        spinner.style.height = '60px';
        
        // SVG animation
        spinner.innerHTML = `
            <svg width="60" height="60" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="40" stroke="#3498db" stroke-width="8" fill="none" />
                <circle cx="50" cy="50" r="40" stroke="#f3f3f3" stroke-width="8" fill="none" stroke-dasharray="251" stroke-dashoffset="50" transform="rotate(-90 50 50)">
                    <animate attributeName="stroke-dashoffset" values="251;0" dur="1s" repeatCount="indefinite"/>
                </circle>
            </svg>
        `;
    
        document.body.appendChild(spinner);
    }

    // Custom method to hide the loading spinner
    hideLoadingSpinner() {
        const spinner = document.querySelector('.loading-spinner');
        if (spinner) {
            spinner.remove();
        }
    }


    createFullScreenBtnUi() {
        const buttonContainer = document.createElement('div');
        buttonContainer.style.position = 'fixed';
        buttonContainer.style.bottom = '20px';
        buttonContainer.style.right = '80px';
        buttonContainer.style.zIndex = '1000'; // Ensure buttons are above other UI elements
        buttonContainer.style.display = 'flex';
        buttonContainer.style.flexDirection = 'column';
        buttonContainer.style.gap = '10px'; // Spacing between buttons
    
        // FullScreen Button
        const fullScreenBtn = document.createElement('button');
        fullScreenBtn.style.background = '#18181A';
        fullScreenBtn.style.height = '42px';  // Adjusted for better visibility on iOS
        fullScreenBtn.style.width = '42px';   // Adjusted for better visibility on iOS
        fullScreenBtn.style.display = 'block';
        fullScreenBtn.style.border = '2px solid #0b141a';
       
    
        const maximizeIcon = `
            <svg id="maximizeIcon" x="0px" y="0px" viewBox="0 0 384.97 384.97" style="width: 100%; height: 100%;">
                <g class="svgIcon">
                    <path d="M384.97,12.03c0-6.713-5.317-12.03-12.03-12.03H264.847c-6.833,0-11.922,5.39-11.934,12.223c0,6.821,5.101,11.838,11.934,11.838h96.062l-0.193,96.519c0,6.833,5.197,12.03,12.03,12.03c6.833-0.012,12.03-5.197,12.03-12.03l0.193-108.369c0-0.036-0.012-0.06-0.012-0.084C384.958,12.09,384.97,12.066,384.97,12.03z"></path>
                    <path d="M120.496,0H12.403c-0.036,0-0.06,0.012-0.096,0.012C12.283,0.012,12.247,0,12.223,0C5.51,0,0.192,5.317,0.192,12.03L0,120.399c0,6.833,5.39,11.934,12.223,11.934c6.821,0,11.838-5.101,11.838-11.934l0.192-96.339h96.242c6.833,0,12.03-5.197,12.03-12.03C132.514,5.197,127.317,0,120.496,0z"></path>
                    <path d="M120.123,360.909H24.061v-96.242c0-6.833-5.197-12.03-12.03-12.03S0,257.833,0,264.667v108.092c0,0.036,0.012,0.06,0.012,0.084c0,0.036-0.012,0.06-0.012,0.096c0,6.713,5.317,12.03,12.03,12.03h108.092c6.833,0,11.922-5.39,11.934-12.223C132.057,365.926,126.956,360.909,120.123,360.909z"></path>
                    <path d="M372.747,252.913c-6.833,0-11.85,5.101-11.838,11.934v96.062h-96.242c-6.833,0-12.03,5.197-12.03,12.03s5.197,12.03,12.03,12.03h108.092c0.036,0,0.06-0.012,0.084-0.012c0.036-0.012,0.06,0.012,0.096,0.012c6.713,0,12.03-5.317,12.03-12.03V264.847C384.97,258.014,379.58,252.913,372.747,252.913z"></path>
                </g>
            </svg>`;
    
        const minimizeIcon = `
            <svg id="minimizeIcon" x="0px" y="0px" viewBox="0 0 385.331 385.331" style="width: 100%; height: 100%;">
                <g class="svgIcon">
                    <path d="M264.943,156.665h108.273c6.833,0,11.934-5.39,11.934-12.211c0-6.833-5.101-11.85-11.934-11.838h-96.242V36.181c0-6.833-5.197-12.03-12.03-12.03s-12.03,5.197-12.03,12.03v108.273c0,0.036,0.012,0.06,0.012,0.084c0,0.036-0.012,0.06-0.012,0.096C252.913,151.347,258.23,156.677,264.943,156.665z"></path>
                    <path d="M120.291,24.247c-6.821,0-11.838,5.113-11.838,11.934v96.242H12.03c-6.833,0-12.03,5.197-12.03,12.03c0,6.833,5.197,12.03,12.03,12.03h108.273c0.036,0,0.06-0.012,0.084-0.012c0.036,0,0.06,0.012,0.096,0.012c6.713,0,12.03-5.317,12.03-12.03V36.181C132.514,29.36,127.124,24.259,120.291,24.247z"></path>
                    <path d="M120.387,228.666H12.115c-6.833,0.012-11.934,5.39-11.934,12.223c0,6.833,5.101,11.85,11.934,11.838h96.242v96.423c0,6.833,5.197,12.03,12.03,12.03c6.833,0,12.03-5.197,12.03-12.03V240.877c0-0.036-0.012-0.06-0.012-0.084c0-0.036,0.012-0.06,0.012-0.096C132.418,233.983,127.1,228.666,120.387,228.666z"></path>
                    <path d="M373.3,228.666H265.028c-0.036,0-0.06,0.012-0.084,0.012c-0.036,0-0.06-0.012-0.096-0.012c-6.713,0-12.03,5.317-12.03,12.03v108.273c0,6.833,5.39,11.922,12.223,11.934c6.821,0.012,11.838-5.101,11.838-11.922v-96.242H373.3c6.833,0,12.03-5.197,12.03-12.03S380.134,228.678,373.3,228.666z"></path>
                </g>
            </svg>`;
    
        fullScreenBtn.innerHTML = maximizeIcon;
    
        fullScreenBtn.onclick = () => {
            if (!document.fullscreenElement && !document.webkitFullscreenElement) {
                fullScreenBtn.innerHTML = minimizeIcon;
                const docEl = document.documentElement;
                if (docEl.requestFullscreen) {
                    docEl.requestFullscreen();
                } else if (docEl.webkitRequestFullscreen) {
                    docEl.webkitRequestFullscreen(); // Safari
                }
            } else {
                fullScreenBtn.innerHTML = maximizeIcon;
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen(); // Safari
                }
            }
        };
    
        buttonContainer.appendChild(fullScreenBtn);
        document.body.appendChild(buttonContainer);
    }
    

    createVolumeBtnUi() {


        const buttonContainer = document.createElement('div');
        buttonContainer.style.position = 'fixed';
        buttonContainer.style.bottom = '20px';
        buttonContainer.style.right = '20px';
        buttonContainer.style.zIndex = '1000'; // Ensure buttons are above other UI elements
        buttonContainer.style.display = 'flex';
        buttonContainer.style.flexDirection = 'column';
        buttonContainer.style.gap = '10px'; // Spacing between buttons
    

        // Volume Button
        const volumeBtn = document.createElement('button');
        volumeBtn.style.background='#18181A';
        volumeBtn.style.height='42px';
        volumeBtn.style.width='42px';
        volumeBtn.style.display = 'block';

        volumeBtn.style.border='2px solid #0b141a'

        volumeBtn.innerHTML = `<svg id="audioIconUnmuted" x="31px" y="28px" viewBox="0 0 31 28" style="width: 100%; height: 100%;" >
            <g class="svgIcon">
                <path d="M21.0008,0.0720318C20.556,-0.117811,20.0379,0.0805569,19.8435,0.514827C19.6492,0.949098,19.8522,1.45512,20.297,1.6449C25.1233,3.70434,28.242,8.35761,28.2422,13.4998C28.2422,18.6423,25.1234,23.2957,20.2966,25.3551C19.8518,25.5448,19.6488,26.0508,19.8431,26.4852C19.9874,26.8077,20.3102,27,20.649,27C20.7664,27,20.8859,26.9769,21.0004,26.928C26.4674,24.5954,30,19.3246,30,13.4997C29.9998,7.67531,26.4674,2.40462,21.0008,0.0720318Z"></path>
                <path d="M21.2351,6.69434C20.8735,6.37822,20.3179,6.4082,19.9941,6.76134C19.6703,7.11454,19.701,7.65706,20.0627,7.97324C21.6712,9.37932,22.5937,11.3937,22.5937,13.5C22.5937,15.6062,21.6711,17.6206,20.0627,19.0267C19.701,19.3428,19.6703,19.8854,19.9941,20.2386C20.1677,20.4279,20.4079,20.5244,20.6492,20.5244C20.8579,20.5244,21.0674,20.4522,21.2351,20.3056C23.2157,18.5744,24.3515,16.0937,24.3515,13.4999C24.3515,10.9061,23.2156,8.42553,21.2351,6.69434Z"></path>
                <path d="M15.3973,2.16277C15.1018,2.01658,14.7473,2.04502,14.4803,2.23623L7.65176,7.12598H0.878906C0.393516,7.12598,0,7.51024,0,7.98422V19.0155C0,19.4895,0.393516,19.8738,0.878906,19.8738H7.6517L14.4802,24.7637C14.6339,24.8737,14.8164,24.9298,15.0001,24.9298C15.1356,24.9298,15.2718,24.8992,15.3973,24.8371C15.6927,24.6909,15.8789,24.395,15.8789,24.0716V2.9282C15.8789,2.60482,15.6927,2.30884,15.3973,2.16277ZM14.121,22.3778L8.45918,18.3234C8.30842,18.2154,8.12637,18.1572,7.93939,18.1572H1.75781V8.84246H7.93939C8.12631,8.84246,8.30842,8.78422,8.45912,8.67636L14.121,4.62197V22.3778Z"></path>
            </g>
        </svg>
`;
       
    
        volumeBtn.onclick = () => {
            isVolumeMuted = !isVolumeMuted; // Toggle volume state
            volumeBtn.innerHTML = isVolumeMuted ? `<svg id="audioIconMuted" x="31px" y="28px" viewBox="0 -1 31 28" style="width: 100%; height: 100%;"> 
            <g class="svgIcon">
                <path d="M16.3501,0.829286C16.046,0.678799,15.6811,0.708072,15.4062,0.904912L8.37681,5.93848H1.40476C0.90509,5.93848,0.5,6.33404,0.5,6.82196V18.1777C0.5,18.6657,0.90509,19.0612,1.40476,19.0612H8.37675L15.4061,24.0949C15.5643,24.2082,15.7522,24.2659,15.9412,24.2659C16.0808,24.2659,16.221,24.2344,16.3501,24.1705C16.6542,24.02,16.8459,23.7154,16.8459,23.3825V1.61724C16.8459,1.28434,16.6542,0.979655,16.3501,0.829286ZM15.0364,21.6389L9.20798,17.4652C9.05278,17.3541,8.86538,17.2942,8.6729,17.2942H2.30951V7.70544H8.6729C8.86532,7.70544,9.05278,7.64548,9.20792,7.53446L15.0364,3.36082V21.6389Z"></path>
                <path d="M25.2799,11.7634L21.8442,8.32748C21.6206,8.10399,21.6206,7.74264,21.8442,7.51915C22.0676,7.29566,22.429,7.29566,22.6525,7.51915L26.0883,10.955L29.524,7.51915C29.7476,7.29566,30.1088,7.29566,30.3323,7.51915C30.5559,7.74264,30.5559,8.10399,30.3323,8.32748L26.8966,11.7634L30.3323,15.1992C30.5559,15.4227,30.5559,15.7841,30.3323,16.0076C30.2209,16.1191,30.0745,16.1751,29.9281,16.1751C29.7818,16.1751,29.6355,16.1191,29.524,16.0076L26.0883,12.5717L22.6525,16.0076C22.541,16.1191,22.3947,16.1751,22.2483,16.1751C22.102,16.1751,21.9556,16.1191,21.8442,16.0076C21.6206,15.7841,21.6206,15.4227,21.8442,15.1992L25.2799,11.7634Z"></path>
            </g>
        </svg>
` : `       <svg id="audioIconUnmuted" x="31px" y="28px" viewBox="0 0 31 28" style="width: 100%; height: 100%;">
            <g class="svgIcon">
                <path d="M21.0008,0.0720318C20.556,-0.117811,20.0379,0.0805569,19.8435,0.514827C19.6492,0.949098,19.8522,1.45512,20.297,1.6449C25.1233,3.70434,28.242,8.35761,28.2422,13.4998C28.2422,18.6423,25.1234,23.2957,20.2966,25.3551C19.8518,25.5448,19.6488,26.0508,19.8431,26.4852C19.9874,26.8077,20.3102,27,20.649,27C20.7664,27,20.8859,26.9769,21.0004,26.928C26.4674,24.5954,30,19.3246,30,13.4997C29.9998,7.67531,26.4674,2.40462,21.0008,0.0720318Z"></path>
                <path d="M21.2351,6.69434C20.8735,6.37822,20.3179,6.4082,19.9941,6.76134C19.6703,7.11454,19.701,7.65706,20.0627,7.97324C21.6712,9.37932,22.5937,11.3937,22.5937,13.5C22.5937,15.6062,21.6711,17.6206,20.0627,19.0267C19.701,19.3428,19.6703,19.8854,19.9941,20.2386C20.1677,20.4279,20.4079,20.5244,20.6492,20.5244C20.8579,20.5244,21.0674,20.4522,21.2351,20.3056C23.2157,18.5744,24.3515,16.0937,24.3515,13.4999C24.3515,10.9061,23.2156,8.42553,21.2351,6.69434Z"></path>
                <path d="M15.3973,2.16277C15.1018,2.01658,14.7473,2.04502,14.4803,2.23623L7.65176,7.12598H0.878906C0.393516,7.12598,0,7.51024,0,7.98422V19.0155C0,19.4895,0.393516,19.8738,0.878906,19.8738H7.6517L14.4802,24.7637C14.6339,24.8737,14.8164,24.9298,15.0001,24.9298C15.1356,24.9298,15.2718,24.8992,15.3973,24.8371C15.6927,24.6909,15.8789,24.395,15.8789,24.0716V2.9282C15.8789,2.60482,15.6927,2.30884,15.3973,2.16277ZM14.121,22.3778L8.45918,18.3234C8.30842,18.2154,8.12637,18.1572,7.93939,18.1572H1.75781V8.84246H7.93939C8.12631,8.84246,8.30842,8.78422,8.45912,8.67636L14.121,4.62197V22.3778Z"></path>
            </g>
        </svg>
`;
     
            this.toggleSound();
            volumeBtn.blur();
        };
    

        buttonContainer.appendChild(volumeBtn);
    
        // Add container to body
        document.body.appendChild(buttonContainer);
    }


     toggleSound = () => {
       
        
            const audioElement = this.stream._webRtcController.streamController.audioElement;
            const audioTracks = audioElement.srcObject?.getAudioTracks(); // Get audio tracks
            
            if (audioTracks && audioTracks.length > 0) {
                audioTracks[0].enabled = !audioTracks[0].enabled; // Toggle mute/unmute the first audio track

                isVolumeMuted = !audioTracks[0].enabled;
            } else {
            }
    
    };


    
}

module.exports = CustomApplication;
