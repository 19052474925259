
const { PixelStreaming,  Config,NumericParameters,Flags, OptionParameters } = require('@epicgames-ps/lib-pixelstreamingfrontend-ue5.5');
const CustomApplication = require('./CustomApplication'); // Adjust the path as needed
const { Application } = require('@epicgames-ps/lib-pixelstreamingfrontend-ui-ue5.5');

function getRandom4DigitNumber() {
    return Math.floor(Math.random() * 9000) + 1000;
}


function showLoadingSpinner() {
    const spinner = document.createElement('div');
    spinner.classList.add('loading-spinner');
    spinner.style.position = 'absolute';
    spinner.style.top = '35%';
    spinner.style.left = '50%';
    spinner.style.transform = 'translate(-50%, -50%)';
    spinner.style.width = '60px';
    spinner.style.height = '60px';
    
    // SVG animation
    spinner.innerHTML = `
        <svg width="60" height="60" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="40" stroke="#3498db" stroke-width="8" fill="none" />
            <circle cx="50" cy="50" r="40" stroke="#f3f3f3" stroke-width="8" fill="none" stroke-dasharray="251" stroke-dashoffset="50" transform="rotate(-90 50 50)">
                <animate attributeName="stroke-dashoffset" values="251;0" dur="1s" repeatCount="indefinite"/>
            </circle>
        </svg>
    `;

    document.body.appendChild(spinner);
}

function hideLoadingSpinner() {
    const spinner = document.querySelector('.loading-spinner');
    if (spinner) {
        spinner.remove();
    }
}

function StreamPixelApplication(settings) {

    
    showLoadingSpinner();

    var streamerId = getRandom4DigitNumber();
    var ssUrl = "wss://signalling.streampixel.io/?StreamerId="+streamerId+"&ProjectId="+settings.appId

    const initialSettings = {
        AutoConnect: settings.AutoConnect,
        ss: ssUrl,
        WaitForStreamer: false,
        StreamerId:streamerId,
        TouchInput:true,
        XRControllerInput:settings.xrInput,
      };


    const config = new Config({
       initialSettings,
    
    }); 


    
    config.setOptionSettingValue(OptionParameters.PreferredCodec,'AV1');

    config.setFlagEnabled(Flags.ForceTURN,true);
    config.setFlagEnabled(Flags.MatchViewportResolution,settings.resolution);
    config.setFlagEnabled(Flags.TouchInput,true);
    config.setFlagEnabled(Flags.FakeMouseWithTouches,false);
    config.setFlagEnabled(Flags.StartVideoMuted,true);
    config.setFlagEnabled(Flags.BrowserSendOffer,false);
    config.setFlagEnabled(Flags.AFKDetection,true);
    config.setFlagEnabled(Flags.ForceMonoAudio,false);
    config.setFlagEnabled(Flags.AutoPlayVideo,true);
    config.setFlagEnabled(Flags.HideUI,true);
    config.setFlagEnabled(Flags.GamepadInput,settings.gamepadInput);
    config.setFlagEnabled(Flags.HoveringMouseMode,settings.hoverMouse);
    config.setFlagEnabled(Flags.MouseInput,settings.mouseInput);
    config.setFlagEnabled(Flags.IsQualityController,true);
    config.setFlagEnabled(Flags.KeyboardInput,settings.keyBoardInput);
    config.setFlagEnabled(Flags.UseMic,settings.useMic);
    config.setFlagEnabled(Flags.AFKDetection, true);
    config.setNumericSetting(NumericParameters.WebRTCFPS,60);
    config.setNumericSetting(NumericParameters.MaxQP, 40);
    config.setNumericSetting(NumericParameters.MinQP, 20);
    config.setNumericSetting(NumericParameters.AFKTimeoutSecs, settings.afktimeout);
    config.setNumericSetting(NumericParameters.WebRTCMinBitrate, 100);
    config.setNumericSetting(NumericParameters.WebRTCMaxBitrate, 1000000);
    config.setNumericSetting(NumericParameters.StreamerAutoJoinInterval, 3000);
    config.setNumericSetting(NumericParameters.MaxReconnectAttempts, 0);
    const pixelStreaming = new PixelStreaming(config);



    
 //wso && wso.onmessage((msg)=>console.log(msg))

 //wso &&  wso.onclose((event)=>console.log(event))


    const uiOptions = {
        settingsPanelConfig:false,
        stream:pixelStreaming,
       
    }
    

    const appStream = new Application(uiOptions);


    appStream.showTextOverlay('Starting connection to Streampixel server, please wait');


    appStream.onConnectAction = function() {
        appStream.showTextOverlay('Starting connection to Streampixel server, please wait');

    }
    appStream.onVideoInitialized = function() {

      
        const videoElement = appStream && appStream.stream.videoElementParent.querySelector("video");

        videoElement.style.zIndex = 100;
  
     
       
      appStream.stream.emitConsoleCommand('t.maxFPS 60');
      

      appStream.stream.emitConsoleCommand('PixelStreaming.WebRTC.MinBitrate 1000');

    
    };

    appStream.onWebRtcConnecting = function() {

        appStream.showTextOverlay('Almost there, hold tight- awesomeness loading');
    }
    

    appStream.onWebRtcConnected = function() {
        appStream.showTextOverlay('Sharpening pixels and buffing the details...');
        
        }
        appStream.onWebRtcAutoConnect= function() {
            appStream.showTextOverlay('Auto Connecting Now');
        }
    
    
    
        appStream.onWebRtcSdp = function() {
            appStream.showTextOverlay('Sharpening pixels and buffing the details...');
    }



    var wsController = appStream.stream.signallingProtocol;
        
   
    var isConnected = wsController.isConnected();
   if(isConnected){
    
    wsController.transport.on('message', (msgRaw) => {
        try {
            
            if(msgRaw.message == "You are in Queue"){

                var msgFormate = msgRaw.message +" "+msgRaw.position;
appStream.showTextOverlay(msgFormate);
        
        
            }

        } catch (error) {
            console.error("Failed to parse message:", error);
            return;
        }
    })

    wsController.transport.on('close',(reason)=>{
       appStream.showDisconnectOverlay();
    })

   }

    return {
        pixelStreaming,
        appStream
    };
}

module.exports = { StreamPixelApplication };